exports.components = {
  "component---src-pages-[culture]-customer-product-[slug]-js": () => import("./../../../src/pages/[culture]/customer-product/[slug].js" /* webpackChunkName: "component---src-pages-[culture]-customer-product-[slug]-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/categoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-standard-page-js": () => import("./../../../src/templates/standardPage.js" /* webpackChunkName: "component---src-templates-standard-page-js" */),
  "component---src-templates-sub-category-page-js": () => import("./../../../src/templates/subCategoryPage.js" /* webpackChunkName: "component---src-templates-sub-category-page-js" */)
}

